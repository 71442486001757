import React from 'react';

import { Helmet } from 'react-helmet';

import Header from 'components/common/header';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import {
	PageContainer,
	SectionTitle,
	ContentText,
	BoldText,
	StaticList,
	PrivacyTable,
	DetailsContainer,
	OrderedList,
	TableContainer,
} from './styles';
import styled from 'styled-components';

const meta = PageMeta.getStatic('privacy');

const policyUpdate = true;

const Container = styled.div`
	p {
		margin-bottom: 16px;
	}
`;

const OrderedListContainer = styled.ol`
	padding-left: 16px;
	list-style-type: decimal;
	counter-reset: item;

	> li {
		display: block;
		margin-top: 8px;
		counter-increment: item;
	}

	> ol {
		counter-reset: item;
	}

	> li:before {
		content: counters(item, '.') '. ';
		width: 40px;
		display: inline-block;
		margin-bottom: 16px;
	}
`;

const UnorderedListContainer = styled.ul`
	padding-left: 20px;
	list-style-type: disc;
	counter-reset: none;

	li {
		list-style-type: disc;
		counter-increment: none;
	}
`;

const SubHeaderText = styled(BoldText)`
	margin-bottom: 16px;
`;

const PrivacyPolicy = () => {
	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header searchable />
			<TitleBar title="Privacy Policy"></TitleBar>
			<PageContainer>
				<Container>
					<p>
						<b>DigsConnect (Pty) Ltd.</b> (reg: 2017/040276/07) (“<b>DigsConnect</b>”) adheres to
						the highest standards of protecting your personal information when we process it by
						virtue of your use of our Services, your use of our website{' '}
						<a href="http://www.digsconnect.com">www.digsconnect.com</a> or any of its related
						blogs, websites, applications or platforms (collectively, “<b>the Website</b>”), when
						contracting with us or by providing us with your personal information in any other way.
						As such, we have created this privacy policy (“<b>Privacy Policy</b>”) for you to read
						and understand how we safeguard your personal information and respect your privacy.
					</p>

					<p>
						Please note that DigsConnect is a private company duly registered and operating in
						accordance with the laws of South Africa.
					</p>

					<p>
						Please ensure that you read all the provisions below, and our other DigsConnect rules
						and policies which may apply from time to time and made available to you, to understand
						all of your, and our, rights and duties.
					</p>

					<OrderedListContainer>
						<li>
							<SubHeaderText>Important information and who we are</SubHeaderText>
							<p>
								<u>Purpose of this Privacy Policy</u>
							</p>
							<p>
								This Privacy Policy aims to give you information on how DigsConnect collects and
								processes your personal data through any form of your engagement with us.
							</p>
							<p>
								This Privacy Policy complies with, and facilitates the obligations required from,
								the European Commission’s General Data Protection Regulation, 2016/679 (“
								<b>GDPR</b>”) as well as the South African Protection of Personal Information Act,
								No. 4 of 2013 (“<b>POPI</b>”), as amended.
							</p>
							<p>
								It is important that you read this Privacy Policy together with any other privacy
								policy or fair processing notice we may provide on specific occasions when we are
								collecting or processing personal data, so that you are fully aware of how and why
								we are using your data. This Privacy Policy supplements the other notices and is not
								intended to override them.
							</p>
							<p>
								We do not process special categories of personal data. Do not provide us with any
								such personal data, where the provision of same will constitute an immediate and
								automatic material breach of these terms. We may in limited instances process the
								data of minors, where we assist a minor tenant. Should you be a minor tenant, you
								may only use the Services and/or provide us with your personal data if your
								parent/guardian accepts this Privacy Policy on your behalf and supervises your use
								of the Services/Website at all times. In such a situation, your parent/guardian
								expressly consents to our processing of their child’s/ward’s personal data and
								accepts all liability for their child’s/ward’s use of the Services/Website at all
								times.
							</p>
							<p>
								<u>Data Controller and Processor</u>
							</p>
							<p>
								DigsConnect is the “Data Controller” (as defined in the GDPR) and is responsible for
								your personal data in instances where we decide the processing operations concerning
								your personal data.{' '}
							</p>
							<p>
								We also operate as a “Processor” of personal data on behalf of a Data Controller
								(such as for referral accommodation providers Student.com), where that Data
								Controller’s privacy terms will apply. For example, should you voluntarily choose to
								have accommodation services provided by Student.com via our platform, we will share
								your limited data with them. We have appointed a data representative at DigsConnect
								who is responsible for overseeing questions in relation to this Privacy Policy. If
								you have any questions about this Privacy Policy, including any requests to exercise
								your legal rights, please contact the representative using the details set out
								below.
							</p>
							<p>
								<u>Our Contact Details</u>
							</p>
							<p>
								<UnorderedListContainer>
									<li>Full name: DigsConnect (Pty) Ltd</li>
									<li>Email address: data@digsconnect.com</li>
								</UnorderedListContainer>
							</p>
							<p>
								You have the right to make a complaint at any time to the office of the South
								African Information Regulator or any relevant European Information Regulator. We
								would, however, appreciate the chance to deal with your concerns before you approach
								any such regulator, so please contact us in the first instance.
							</p>
							<p>
								<u>Changes to the Privacy Policy</u>
							</p>
							<p>
								This Privacy Policy version was last updated on 25 June 2024 and historic versions
								are archived and can be provided on request.
							</p>
							<p>
								Please keep us informed if your personal information changes during your
								relationship with us. We will not be liable for any incorrect personal data it may
								hold on/about you if you do not notify us of changes.
							</p>
							<p>
								This Privacy Policy is subject to change without notice and is updated or amended
								from time to time and will be effective once we upload the amended version to the
								Platform. Your continued access or use of our Services constitutes your acceptance
								of this Privacy Policy, as amended. It is your responsibility to read this document
								periodically to ensure you are aware of any changes.
							</p>
							<p>
								<u>Third-Party Links on Website or otherwise</u>
							</p>
							<p>
								The Website may include links to third-party websites, plug-ins, and applications.
								Clicking on those links or enabling those connections may allow third parties to
								collect or share data about you. We do not control these third-party websites and
								are not responsible for their privacy statements or terms. When you leave our
								Website, or engage with such third parties, we encourage you to read the distinct
								privacy policy of every third-party you engage with.
							</p>
							<p>
								Where your personal information has been shared with DigsConnect by any of our
								third-party affiliates we accept that you have provided your consent to such third
								party for the sharing of your personal information or there is a legal justification
								for its sharing in accordance with that third-party's privacy statement or terms.
							</p>
						</li>
						<li>
							<SubHeaderText>The data we collect about you</SubHeaderText>
							<p>
								“Personal data”, or “personal identifiable information”, means any information about
								an individual, both natural and juristic entities, from which that entity can be
								identified. It does not include data where the identity has been removed (anonymous
								data).
							</p>

							<p>
								We may collect, use, store and transfer (“<b>process</b>”) different kinds of
								personal data about you which we have grouped together as follows:
							</p>

							<p>
								<b>Identity Data</b> including full name, country of residence, username or similar
								identifier, date of birth and gender;
							</p>

							<p>
								<b>Contact Data</b> including email address, social media contact details and
								telephone numbers;
							</p>

							<p>
								<b>Financial Data</b> including bank account details, payment details, third-party
								payment provider information and payment card details;
							</p>

							<p>
								<b>Transaction Data</b> including details about payments to and from you, contracts,
								contractual terms, contract fees, signups, subscriptions, invoices and other details
								of products and Services you have obtained from us;
							</p>

							<p>
								<b>Social Media Data</b> including information accessible on your publicly available
								profile such as full name, comments, display pictures, direct messages and email
								address;
							</p>

							<p>
								<b>Technical Data</b> including internet protocol address/es, your login data,
								browser type and version, time zone setting and location, cookies, browser plug-in
								types and versions, operating system and platform and other technology on the
								devices you use to access the Website;
							</p>

							<p>
								<b>Profile Data</b> including your Website username and password, educational
								information (including the location, area, and level of your studies), preferences,
								feedback, ratings and reviews, any information which you provide to DigsConnect upon
								creating your DigsConnect account and survey responses;
							</p>

							<p>
								<b>Usage Data</b> including information about how you use our company, Website,
								Website-based messages between registered users on the Website, recorded telephone
								calls between us and a tenant or landlord and/or tenant and landlord and us,
								surveys, events and services;
							</p>

							<p>
								<b>Marketing and Communications Data</b> including your preferences in receiving
								notices and marketing from us and our third parties and your communication
								preferences preferences as well as details of which communications were sent to you
								and how they were sent; and{' '}
							</p>

							<p>
								<b>Support Data</b> including all messages you send to us via email, via social
								media or through the Website as well as records of all conversations had with you
								over telephone.{' '}
							</p>

							<p>
								We also collect, use and share <b>Aggregated Data</b> such as statistical or
								demographic data for any purpose. Aggregated Data may be derived from your personal
								data but is not considered personal data in law as this data does <b>not</b>{' '}
								directly or indirectly reveal your identity. For example, we may aggregate your
								Usage Data to calculate the percentage of users accessing a specific Website
								feature. However, if we combine or connect Aggregated Data with your personal data
								so that it can directly or indirectly identify you, we treat the combined data as
								personal data which will be used in accordance with this Privacy Policy.
							</p>

							<p>
								Where we need to collect personal data by law, or under the terms of a contract we
								have with you and you fail to provide that data when requested, we may not be able
								to perform the contract we have or are trying to enter into with you.
							</p>
						</li>
						<li>
							<SubHeaderText>How is your personal data collected?</SubHeaderText>
							<p>We use different methods to collect data from and about you, including through:</p>
							<p>
								<u>Direct interactions</u>: You may give us your personal data by using our Services
								or by corresponding with us through the Website, social media, email or otherwise.
								This includes personal data you provide when you:
							</p>
							<p>
								<UnorderedListContainer>
									<li>use our Services (as a landlord, tenant or otherwise);</li>
									<li>use our Website;</li>
									<li>contract with us;</li>
									<li>consult with us;</li>
									<li>provide us with your services;</li>
									<li>complete forms;</li>
									<li>interact with us via webinar or social media</li>
									<li>subscribe to any of our publications;</li>
									<li>request information to be sent to you; or</li>
									<li>give us some feedback.</li>
								</UnorderedListContainer>
							</p>
							<p>
								<u>Automated technologies or interactions</u>: As you interact with our Website, we
								may automatically collect Technical and Usage Data about your equipment, browsing
								actions and patterns. We may collect this personal data by using cookies, tracking,
								server logs and other similar technologies. We may also receive Technical Data about
								you if you visit other websites employing our cookies.
							</p>
							<p>
								<u>Third parties or publicly available sources</u>: We may receive personal data
								about you from various third parties and public sources including:
							</p>
							<p>
								<UnorderedListContainer>
									<li>analytics providers; </li>
									<li>marketing platforms and providers; </li>
									<li>messaging and communication platforms; </li>
									<li>customer relationship management software; </li>
									<li>social media platforms; </li>
									<li>
										third parties who DigsConnect has partnered with for marketing and business
										development;{' '}
									</li>
									<li>
										private third parties such as various college and university partners of
										DigsConnect.{' '}
									</li>
								</UnorderedListContainer>
							</p>
						</li>
						<li>
							<SubHeaderText>How we use your personal data</SubHeaderText>
							<p>
								We will only use your personal data when the law allows us to and for legitimate
								reasons, which you hereby expressly understand and consent to. Most commonly, we
								will use your personal data in the following circumstances:
							</p>
							<p>
								<UnorderedListContainer>
									<li>
										where we have your express <b>consent</b> to do so;
									</li>
									<li>
										where we need to consult with you or <b>perform on the contract</b> we are about
										to enter into or have entered into with you;
									</li>
									<li>
										where it is necessary for <b>our legitimate interests</b> (or those of a third
										party) and your interests and fundamental rights do not override those
										interests;{' '}
									</li>
									<li>
										where we need to comply with a <b>legal or regulatory obligation</b>.{' '}
									</li>
								</UnorderedListContainer>
							</p>
						</li>
						<li>
							<SubHeaderText>Purposes for which we will use your personal data</SubHeaderText>
							<p>
								We have set out below the purpose for which we will process your personal data,
								which includes:
							</p>
							<p>
								<UnorderedListContainer>
									<li>
										to engage with you after you have contacted us via our Website or otherwise;{' '}
									</li>
									<li>
										to allow you to use our Services and Website and provide you with our Services
										as contracted;{' '}
									</li>
									<li>to contract with you as a service provider or contractor to DigsConnect; </li>
									<li>
										to provide your personal data to our service providers and contractors who need
										you personal information to provide their services to us or to you directly;{' '}
									</li>
									<li>
										to process and service your payment for any Services rendered by DigsConnect or
										our service providers;
									</li>
									<li>to manage payments, fees, and charges;</li>
									<li>to meet our regulatory and compliance requirements;</li>
									<li>
										to manage our relationship with you which may include notifying you about
										changes to our Terms, Privacy Policy, or Services or the delivery of
										communications and the effectiveness thereof;
									</li>
									<li>
										to administer and protect our company, Website and Services (including
										troubleshooting, data analysis, testing, system maintenance, support, reporting
										and hosting of data);
									</li>
									<li>
										to use data analytics to improve our Website, Services, client relationships and
										experiences;{' '}
									</li>
									<li>
										to provide you with direct marketing and make suggestions about Services or
										events that may be of interest; and
									</li>
									<li>
										to create remarketing lists and/or third-party audience segments. These lists
										and segments may be shared with third-party companies for marketing purposes.
										Please note that your personal data will not be shared directly with these
										third-party companies and is fully anonymised and processed in a manner that
										does not disclose your personal information.{' '}
									</li>
								</UnorderedListContainer>
							</p>
							<p>
								Note that we may process your personal data for more than one lawful ground
								depending on the specific purpose for which we are using your data.{' '}
							</p>
							<p>
								<u>Quality Control </u>
							</p>
							<p>
								To provide you with the best service and ensure we are transparent in our
								communications with you, all communications with us including telephone calls will
								be recorded and logged for quality control, staff training, and regulatory
								compliance purposes. You are welcome to request a copy of or a recording of any call
								or interaction you have with us.
							</p>
							<p>
								<u>Marketing</u>
							</p>
							<p>
								We strive to provide you with choices regarding certain personal data uses,
								particularly around marketing and advertising. To manifest your rights attached to
								any marketing sent to you, please use the in-built prompts provided on those
								communications, or contact us.{' '}
							</p>
							<p>
								You will receive marketing communications from us if you have requested our
								Services, requested information from us, or provided us with your details in any
								other circumstance and, in each case, have not opted-out of receiving that
								marketing.
							</p>
							<p>
								You can ask us to stop sending you marketing messages at any time by using the
								built-in prompts or contacting us and requesting us to cease or change your
								marketing preferences. Where you opt-out of receiving marketing messages, this
								opt-out will not apply to other personal information of yours which we process for
								another lawful basis.
							</p>
							<p>
								Your personal data may be used to conduct credit assessments for purposes of
								verifying your ability to fulfil financial obligations, provide advertising,
								promotions or other products and services that may be of particular interest to you.{' '}
							</p>
							<p>
								<u>Third-Party Marketing</u>
							</p>
							<p>
								We may share your personal information with third-party marketers/entities for them
								to contact you. However, and for your personal protection and solace, you will have
								to opt-in to any such communication or processing before these parties can contact
								you and process your personal data for their own purposes.
							</p>
							<p>
								<u>Change of Purpose</u>
							</p>
							<p>
								We will only use your personal data for the purposes for which we collected it,
								unless we reasonably consider that we need to use it for another reason and that
								reason is compatible with the original purpose. If you wish to get an explanation as
								to how the processing for the new purpose is compatible with the original purpose,
								please contact us.
							</p>
							<p>
								If we need to use your personal data for an unrelated purpose, we will notify you
								and we will explain the legal basis which allows us to do so.{' '}
							</p>
							<p>
								Please note that we may process your personal data without your knowledge or
								consent, in compliance with the above rules, where this is required or permitted by
								national law.
							</p>
						</li>
						<li>
							<SubHeaderText>Disclosures of your personal data</SubHeaderText>
							<p>
								We may have to share your personal data with the parties set out below for the
								purposes set out above.
							</p>
							<p>
								<UnorderedListContainer>
									<li>
										Internal Third Parties including other entities or parties in the DigsConnect
										group and their respective directors and employees, acting as joint controllers
										or processors;
									</li>
									<li>
										External Third Parties including:
										<UnorderedListContainer>
											<li>
												third parties under contract with DigsConnect who need your personal data to
												provide their services to us or to you directly pursuant to your use of our
												services (such as software service providers and developers);
											</li>
											<li>
												service providers and contractors providing their services to us and acting
												as processors of your personal data;
											</li>
											<li>
												third party marketing companies making use of our custom audience segments;{' '}
											</li>
											<li>
												college and university partners engaging with DigsConnect to offer our
												Services to their students;{' '}
											</li>
											<li>
												Website partners who have partnered with DigsConnect to offer accommodation
												in various countries (including Student.com);{' '}
											</li>
											<li>
												national governments and/or their respective authorities pursuant to our
												adherence with legislative requirements such as tax; and{' '}
											</li>
											<li>
												professional advisers acting as processors or joint controllers including
												lawyers, bankers, auditors and insurers who provide consultancy, banking,
												legal, insurance and accounting services as required.
											</li>
										</UnorderedListContainer>
									</li>
									<li>
										Third parties to whom we may choose to sell, transfer, or merge parts of our
										organisation or our assets. Alternatively, we may seek to acquire other
										organisations or merge with them. If a change happens to our organisation, then
										the new owners may use your personal data in the same way as set out in this
										Privacy Policy.{' '}
									</li>
								</UnorderedListContainer>
							</p>
							<p>
								We require all third parties to respect the security of your personal data and to
								treat it in accordance with the law. We do not allow our third-party service
								providers to use your personal data for their own purposes and only permit them to
								process your personal data for specified purposes and in accordance with our
								instructions and standards.{' '}
							</p>
						</li>
						<li>
							<SubHeaderText>Cookies</SubHeaderText>
							<p>
								The Website uses “cookies” to automatically collect information and data through the
								standard operation of internet servers. “Cookies” are small text files a website can
								use (and which we may use) to recognise repeat users, facilitate the user’s on-going
								access to and use of a website and allow a website to track usage behaviour and
								compile aggregate data that will allow the website operator to improve the
								functionality of the website and its content, and to display more focused
								advertising to a user by way of third party tools.{' '}
							</p>
							<p>
								The type of information collected by cookies is not used to personally identify you.
								If you do not want information collected using cookies, there is a simple procedure
								in most browsers that allows you to deny or accept the cookie feature.
							</p>
							<p>
								Please note that cookies are necessary to provide you with certain features
								available on our Website, and therefore if you disable the cookies on your browser
								you may not be able to use those features, and your access to our Website will be
								limited. If you do not disable “cookies”, you are deemed to consent to our use of
								any personal data collected and processing using cookies, subject to the provisions
								of this Privacy Policy and our other policies or terms.
							</p>
						</li>
						<li>
							<SubHeaderText>International transfers</SubHeaderText>
							<p>
								We share your personal data within the DigsConnect group of organisations, partners,
								and affiliates, and this may involve transferring and processing your data outside
								of South Africa or the European Economic Area (<b>“EEA”</b>).
							</p>
							<p>
								Whenever we transfer your personal data out of either territory, we ensure a similar
								degree of protection is afforded to it by ensuring at least one of the following
								safeguards is implemented:
							</p>
							<p>
								<UnorderedListContainer>
									<li>
										We will always have a contract in place covering the processing of data between
										the parties;
									</li>
									<li>
										We will only provide your personal data to an entity that processes personal
										information at standards equal to or better than ours; or
									</li>
									<li>
										Where personal data is being shared out of the EEA, we will share personal data
										in accordance with the European Commission’s Standard Contractual Clauses.
									</li>
								</UnorderedListContainer>
							</p>
							<p>
								Please contact us if you want further information on the specific mechanism used by
								us when sharing your personal data.
							</p>
						</li>
						<li>
							<SubHeaderText>Data security</SubHeaderText>
							<p>
								We have put in place appropriate security measures to prevent your personal data
								from being accidentally lost, used or accessed in an unauthorised way, altered or
								disclosed by using appropriate technical and organisational measures. In addition,
								we limit access to your personal data to those persons who have a legitimate need to
								know. They will only process your personal data on our instruction and they are
								subject to a duty of confidentiality.
							</p>
							<p>
								We have put in place procedures to deal with any suspected personal data breach and
								will notify you and any applicable regulator of a breach where we are legally
								required to do so.
							</p>
						</li>
						<li>
							<SubHeaderText>Data retention</SubHeaderText>
							<p>
								We will only retain your personal data for as long as necessary to fulfil the
								purposes we collected it for, including for the purposes of satisfying any legal,
								accounting, or reporting requirements.
							</p>
							<p>
								To determine the appropriate retention period for personal data, we consider the
								amount, nature, and sensitivity of the personal data, the potential risk of harm
								from unauthorised use or disclosure of your personal data, the purposes for which we
								process your personal data, any other applicable law requiring us to retain the data
								and whether we can achieve those purposes through other means, and the applicable
								legal requirements.
							</p>
							<p>
								We may anonymise your personal data for research or statistical purposes in which
								case we may use this information indefinitely without further notice to you.
							</p>
						</li>
						<li>
							<SubHeaderText>Your legal rights</SubHeaderText>
							<p>
								You have rights under data protection laws in relation to your personal data where
								we are the Data Controller over such personal data. Please contact us to find out
								more about, or manifest, these rights:
							</p>
							<p>
								<UnorderedListContainer>
									<li>request access to your personal data;</li>
									<li>request correction of your personal data;</li>
									<li>request erasure of your personal data;</li>
									<li>object to the processing of your personal data;</li>
									<li>request a restriction of processing your personal data;</li>
									<li>request transfer of your personal data; and/or</li>
									<li>right to withdraw consent.</li>
								</UnorderedListContainer>
							</p>
							<p>
								You will not have to pay a fee to access your personal data (or to exercise any of
								your rights). However, we may charge a reasonable fee if your request is clearly
								unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
								request in these circumstances.
							</p>
							<p>
								We may need to request specific information from you to help us confirm your
								identity and ensure your right to access your personal data (or to exercise any of
								your other rights). This is a security measure to ensure that personal data is not
								disclosed to any person who has no right to receive it. We may also contact you to
								ask you for further information in relation to your request to speed up our
								response.
							</p>
							<p>
								We try to respond to all legitimate requests within one month. Occasionally it may
								take us longer than a month if your request is particularly complex or you have made
								several requests. In this case, we will notify you and keep you updated.
							</p>
						</li>
					</OrderedListContainer>
				</Container>
			</PageContainer>
			<Footer />
		</Main>
	);
};

export default PrivacyPolicy;
